import {EligibilityRequestDataSerializer, EligibilityRequestDetailSerializer, PayerAltNameDetailSerializer} from '../@core/api.service';
import {ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES} from '../@core/eligibility_request_response_status_choices';

type InsuranceEligibilityStatus = keyof typeof ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES;

export class InsuranceEligibility implements EligibilityRequestDetailSerializer {
    id: number;
    patient: number;
    payer_name: PayerAltNameDetailSerializer;
    reject_reason: string;
    request_data: EligibilityRequestDataSerializer;
    response_data: any;
    response_data_html: string;
    response_status: string;
    subscriber_id: string;
    timestamp: Date | string;
    transaction_id: string;
    user: number;

    constructor(data: EligibilityRequestDetailSerializer) {
        if (!data) return;
        Object.assign(this, data);
    }

    get insuranceEligibilityStatus() {
        if (!this.response_status) return null;
        return this._insuranceEligibilityStatus[this.response_status as InsuranceEligibilityStatus];
    }

    private _insuranceEligibilityStatus: {
        [status in keyof typeof ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES]: {
            color: string;
            icon: string;
            normalizedStatus: string;
        }
    } = {
            ELIGIBLE: {
                color: 'success',
                icon: 'check-circle',
                normalizedStatus: 'Eligible',
            },
            NOT_ELIGIBLE: {
                color: 'danger',
                icon: 'times-circle',
                normalizedStatus: 'Not Eligible',
            },
            ERROR: {
                color: 'warning',
                icon: 'exclamation-triangle',
                normalizedStatus: 'Error',
            },
            PENDING: {
                color: 'info',
                icon: 'info-circle',
                normalizedStatus: 'Pending',
            },
        };
}

